import { URL_ACCESS } from "../constants";
import type { HallowQueries } from "../types";
import { appendQueries } from "./appendQueries";

export const openAppDownloads = async () => {
  window.location.href = `https://hallow.com/downloads`;
};

export const openAppFavorites = async () => {
  window.location.href = `https://hallow.com/favorites`;
};

export const redirectToAuth = async (
  flowPath = "",
  queries: HallowQueries = {},
) => {
  window.location.href = `${URL_ACCESS}${flowPath}?loginRedirect=${encodeURIComponent(window.location.href)}${appendQueries(queries)}`;
};

export const redirectToSubscribe = async (newWindow: boolean = false) => {
  const url = `${URL_ACCESS}/onboarding?page=subscribe&loginRedirect=${encodeURIComponent(window.location.href)}&subscribeRedirect=${encodeURIComponent(window.location.href)}`;
  if (newWindow) {
    window.open(url);
  } else {
    window.location.href = url;
  }
};
